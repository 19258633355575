.pools-section {
    .ant-form-item-label {
        opacity: 0;
    }

    .ant-space-item {
        width: 100%;
        
        &:nth-child(2) {
            
            .ant-form-item-label {
                opacity: 1;
            }
        }
        &:last-child{

            text-align: left;
        }
        

        // &:nth-child(5),
        // &:nth-child(6),
        // &:nth-child(7) {
        //     // width: 30%;

        //     .ant-form-item-label {
        //         opacity: 1;
        //     }
        // }
    }

    &> .ant-form-item {

            display: flex;
            justify-content: end;
    }

}
.badge-sa{
    position: absolute;
    left: 0;
    height: 100%;
    background: #0000000f;
    padding: 0 6px;
    align-items: center;
    justify-content: center;
    display: flex;

}

.success-step8{
    font-size: 11px;
}

header {
    text-align: center;
    color: green;
    font-size: 24px;
    font-weight: bold;
  }