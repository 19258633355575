.add-prop {

    &.step-3 {

        .ant-form-item-control-input-content {
            display: flex;
        }

        .ant-space-item:first-child {
            width: 100%;
        }
    }

    &.step-9 {
        .ant-checkbox {
            align-self: flex-start
        }
    }
}