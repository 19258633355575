.color-inherit {
  color: inherit !important;
}

.ant-form-item-required {
  width: 100%;
}

.ant-form-horizontal .ant-form-item-label {
  display: flex;
}
.ant-pagination-options {
  display: none !important;
}

 
.ant-pagination .ant-pagination-next .ant-pagination-item-link,.ant-pagination .ant-pagination-prev .ant-pagination-item-link{
  // display: flex !important;
  background: #843911;
  color: #fff;
}

.ant-pagination .ant-pagination-next:hover .ant-pagination-item-link:hover,.ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link:hover{
   background: #843911;
  color: #fff;
}
.text-main {
  color: $primaryCol !important;
}

.ql-editor {
  text-align: right;
}

.ql-direction-rtl {
  margin-bottom: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  text-align: right;
}

.PhoneInputCountrySelectArrow {
  margin-right: 6px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.hide-lable {
  .ant-form-item-label {
    opacity: 0 !important;
  }
}

.grid-checkbox-group {
  .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item.ant-checkbox-group-item {
    @media (min-width: 1200px) {
      width: 23%;
    }
  }
}

.ant-layout {
  .ant-layout {
    margin-right: 260px !important;
  }
  .ant-layout-sider {
    flex: 0 0 260px !important;
    max-width: 260px !important;
    min-width: 200px !important;
    width: 266px !important;
    position: fixed;
    height: 100%;
    overflow-y: scroll;
  }
}

.tabs-section {
  .ant-tabs-tab-btn {
    font-size: 22px;
  }
}





