.makan-statistics {
    .label {
        color: #404b52;
        font-family: "Cairo";
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 20px; /* 111.111% */
        letter-spacing: 0.2px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .value {
        // border: 1px solid #dadada;
        background: $primaryCol;
        width: 100%;
        height: 44px;
        color: #fff;
        font-family: Cairo;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px; /* 94.444% */
        letter-spacing: -0.18px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
    }
}
