.header-custom {
    background: $primaryCol;
    @media (max-width: 750px) {
        padding-inline: 12px;
    }
    @media (min-width: 1200px) {
        padding: 18px 10px !important;
        height: auto;
    }
    .ant-badge {
        background-color: #fff;
        border-radius: 50%;
        border-radius: 22px;
        background: #fff;
        width: 36px;
        height: 36px;

        display: flex;
        align-items: center;
        justify-content: center;
        .anticon {
            margin: 0 !important;
        }
        .ant-badge-count {
            transform: translate(-25%, -50%);
        }
    }
    .drop-user {
        padding: 2px;
        cursor: pointer;
        @media (min-width: 1200px) {
            width: 220px !important;
            height: 66px !important;
        }
        flex-shrink: 0;
        border-radius: 12px;
        border: 1px solid rgba(238, 255, 243, 0.9);
        .ant-avatar {
            border: 1px solid rgba(238, 255, 243, 0.9);
        }
    }
}
