.ant-menu {
  .ant-menu-item {
    color: #000;
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
    letter-spacing: -0.14px;
    a {
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
    }
    .sidebar-icon {
      width: 30px;
      height: 30px;
      background-position: center !important;
      background-size: contain !important;
      background-repeat: no-repeat !important;
    }
    &.ant-menu-item-selected {
      background: $primaryCol;
      color: #fff;

      .sidebar-icon {
        filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(3116deg);
      }
    }
  }
}
